var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apply-form__section apply-form__channel"},[_c('h3',[_vm._v(_vm._s(_vm.$t('application.channel_title')))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"channel","rules":_vm.getValidationRules(_vm.channelSettings.channelValidationMode)},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"outlined":"","flat":"","hide-details":"auto","required":"","item-text":"label","item-value":"value","items":_vm.channelSettings.channels,"label":_vm.getLabel(
              'application.channel',
              _vm.channelSettings.channelValidationMode
            ),"error-messages":errors},model:{value:(_vm.application.channel),callback:function ($$v) {_vm.$set(_vm.application, "channel", $$v)},expression:"application.channel"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"channelComment","rules":_vm.getValidationRules(_vm.channelSettings.channelCommentValidationMode)},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{attrs:{"outlined":"","flat":"","hide-details":"auto","rows":"3","label":_vm.getLabel(
              'application.channelComment',
              _vm.channelSettings.channelCommentValidationMode
            ),"error-messages":errors},model:{value:(_vm.application.channelComment),callback:function ($$v) {_vm.$set(_vm.application, "channelComment", $$v)},expression:"application.channelComment"}})]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }