<template>
  <job-row-view
    v-if="joboffer && jobDetails"
    :apiUrl="apiUrl"
    :joboffer="joboffer"
    :jobDetails="jobDetails"
    :slug="slug"
  ></job-row-view>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import jobRowView from '@/components/jobDetails/job-details-column-view.vue';

export default {
  data: () => ({
    apiUrl: process.env.VUE_APP_API_URL,
    joboffer: null,
    jobDetails: null
  }),

  watch: {
    $route() {
      this.initialize();
    },

    joboffers() {
      if (!this.joboffer) {
        this.initialize();
      }
    }
  },

  computed: {
    ...mapState('jobsModule', {
      joboffers: 'joboffers'
    }),

    ...mapState('companyModule', {
      slug: 'slug'
    })
  },

  async mounted() {
    this.setCurrentJob({ joboffer: null, jobDetails: null });

    if (this.joboffers && this.joboffers.length > 0) {
      await this.initialize();
    }
  },

  methods: {
    ...mapActions('jobsModule', {
      loadJobDetails: 'loadJobDetails',
      loadJobs: 'loadJobs'
    }),

    ...mapMutations({
      setHeroClaim: 'setHeroClaim',
      setCurrentJob: 'jobsModule/setCurrentJob'
    }),

    async initialize() {
      const jobId = this.$route.params.jobId;
      const job = this.joboffers.find(
        (x) => x.jobofferId.toString() === jobId.toString()
      );

      this.joboffer = job;
      // this.setHeroClaim(job.name);

      const response = await this.loadJobDetails({
        slug: this.slug,
        jobofferId: jobId
      });

      this.jobDetails = response;
      this.setCurrentJob({ joboffer: job, jobDetails: response });
    }
  },

  components: {
    jobRowView
  }
};
</script>
