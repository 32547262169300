<template>
  <div class="apply-form__section apply-form__channel">
    <h3>{{ $t('application.channel_title') }}</h3>
    <v-row>
      <v-col cols="12" md="6">
        <ValidationProvider
          v-slot="{ errors }"
          name="channel"
          :rules="getValidationRules(channelSettings.channelValidationMode)"
        >
          <v-select
            v-model="application.channel"
            outlined
            flat
            hide-details="auto"
            required
            item-text="label"
            item-value="value"
            :items="channelSettings.channels"
            :label="
              getLabel(
                'application.channel',
                channelSettings.channelValidationMode
              )
            "
            :error-messages="errors"
          ></v-select>
        </ValidationProvider>
      </v-col>

      <v-col cols="12" md="6">
        <ValidationProvider
          v-slot="{ errors }"
          name="channelComment"
          :rules="
            getValidationRules(channelSettings.channelCommentValidationMode)
          "
        >
          <v-textarea
            v-model="application.channelComment"
            outlined
            flat
            hide-details="auto"
            rows="3"
            :label="
              getLabel(
                'application.channelComment',
                channelSettings.channelCommentValidationMode
              )
            "
            :error-messages="errors"
          ></v-textarea>
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import validationModeEnums from '@/enum/field-validation-mode.js';
import { ValidationProvider } from 'vee-validate';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    channelSettings: {
      type: Object,
      required: true
    }
  },

  computed: {
    application: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    fieldValidationMode() {
      return validationModeEnums.FieldValidationModes;
    },

    notEmptyRule() {
      return [
        (value) => {
          if (!value || value.trim() === '') {
            return this.$t('application.fieldIsMandatory');
          }

          return true;
        }
      ];
    }
  },

  methods: {
    getLabel(labelKey, validationMode) {
      let label = this.$t(labelKey);
      if (validationMode === this.fieldValidationMode.Mandatory) {
        label += '*';
      }

      return label;
    },

    getValidationRules(validationMode) {
      if (validationMode === this.fieldValidationMode.Mandatory) {
        return 'required';
      }

      return '';
    }
  },

  components: {
    ValidationProvider
  }
};
</script>
