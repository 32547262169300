export default {
  GetApplicationFile(objectName, contentType) {
    return fetch(
      `${process.env.VUE_APP_API_URL}/application-file?objectName=${objectName}&contentType=${contentType}`
    );
  },

  LoadForm(jobofferId) {
    return fetch(`${process.env.VUE_APP_API_URL}/form/${jobofferId}`);
  },

  async ParseCv(application, objectId, formId, jobofferId) {
    const result = await fetch(
      `${process.env.VUE_APP_API_URL}/parse-cv?objectId=${objectId}&formId=${formId}&jobofferId=${jobofferId}`,
      {
        timeout: 10000
      }
    );
    const cvData = await result.json();

    // this.SetApplicationValue(application, 'firstName', cvData.firstName);
    // this.SetApplicationValue(application, 'lastName', cvData.lastName);
    // this.SetApplicationValue(application, 'email', cvData.emailAddress);
    // this.SetApplicationValue(application, 'salutationId', cvData.salutation);

    return cvData;
  },

  SubmitApplication(applicationData) {
    // const formData = new FormData();
    // for (const key of Object.keys(applicationData)) {
    //   if (key === 'documents') {
    //     for (const doc in applicationData[key]) {
    //       formData.append(`documents`, applicationData[key][doc]);
    //     }
    //   } else if (key === 'basics') {
    //     for (const index in applicationData[key]) {
    //       formData.append(
    //         `basics[${index}].dataDefinitionId`,
    //         applicationData[key][index].dataDefinitionId
    //       );

    //       formData.append(
    //         `basics[${index}].value`,
    //         applicationData[key][index].value
    //       );
    //     }
    //   } else {
    //     formData.append(key, applicationData[key]);
    //   }
    // }

    // console.log(formData);
    // console.log(formData['basics']);

    const payload = {
      acceptPrivacyPolicy: applicationData.acceptPrivacyPolicy,
      jobofferId: applicationData.jobofferId,
      saluatationId: applicationData.saluatationId,
      title: applicationData.title,
      firstName: applicationData.firstName,
      lastName: applicationData.lastName,
      email: applicationData.email,
      slug: applicationData.slug,
      channel: applicationData.channel,
      channelComment: applicationData.channelComment,
      letterText: applicationData.letterText,

      basics: applicationData.basics?.map((x) => {
        return {
          dataDefinitionId: x.dataDefinitionId,
          value: x.value ? x.value.toString() : ''
        };
      }),

      questions: applicationData.questions?.map((x) => {
        return {
          questionId: x.questionId,
          value: x.value ? x.value.toString() : ''
        };
      }),

      additionalQuestions: applicationData.additionalQuestions,

      cv: applicationData.cv,
      documents: applicationData.documents,
      letter: applicationData.letter,
      disableCvParsing: applicationData.disableCvParsing,
      userPhoto: applicationData.photo,
      graphs: applicationData.graphs
    };

    const body = JSON.stringify(payload);

    return fetch(`${process.env.VUE_APP_API_URL}/application`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: body
    });
  },

  UploadFile(file, fileType) {
    const formData = new FormData();
    formData.append('fileType', fileType);
    formData.append('file', file);

    return fetch(`${process.env.VUE_APP_API_URL}/application-file`, {
      method: 'POST',
      headers: {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      },
      body: formData
    });
  },

  SetApplicationValue(application, field, value) {
    if (value) {
      value = value.toString();
    }

    if (!application[field] || application[field] === '') {
      application[field] = value;
    }
  }
};
