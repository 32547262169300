<template>
  <div class="joboffer-box" @click="navigateToJob">
    <v-row>
      <v-col cols="11">
        <div>
          <strong>
            <span v-if="isFieldEnabled('job-code')">
              {{ joboffer.jobCode }} &mdash;
            </span>
            {{ localize(joboffer.name) }}
          </strong>
        </div>
        <div class="d-block d-sm-flex flex-sm-wrap mt-4 text-sm">
          <item-field
            v-for="field in jobDataFields"
            :key="`job-item_${field}`"
            :joboffer="joboffer"
            :field="field"
          ></item-field>
        </div>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-end text-right">
        <v-icon color="accent">mdi-chevron-right</v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import itemField from './joboffer-item-fields/item-field.vue';

export default {
  props: {
    joboffer: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('companyModule', {
      showExternalAdvert: 'showExternalAdvert',
      jobDataFields: 'jobDataFields'
    })
  },

  methods: {
    navigateToJob() {
      if (this.showExternalAdvert) {
        window.open(this.joboffer.advertUrl, '_blank');
      } else {
        this.$router.push({
          name: 'jobDetails',
          params: {
            jobId: this.joboffer.jobofferId,
            jobName: 'joboffer-name'
          }
        });
      }
    },

    isFieldEnabled(fieldName) {
      return this.jobDataFields.includes(fieldName);
    }
  },

  components: {
    itemField
  }
};
</script>

<style scoped>
.joboffer-box:hover {
  cursor: pointer;
}
</style>
